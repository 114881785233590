<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { usePeerStore } from '~/stores/peers';
import { useLocalFormat } from '~/composables/shared/useLocalFormat';
import { precisRelativeTime, resolvePeerStatusBadge } from '~/utils';

const peersStore = usePeerStore();
const { formatWithTimezone } = useLocalFormat();
const {
  btcLastSynced, evmLastSynced, isNodeConnectingToBtcPeer,
  isNodeConnectingToEvmPeer, evmPeers, btcPeers
} = storeToRefs(peersStore);

const canConnectToBitcoinPeer = computed(() => {
  return !isNodeConnectingToBtcPeer.value && btcPeers.value.length === 0 && btcLastSynced.value > 0;
});

const canConnectToEvmPeer = computed(() => {
  return !isNodeConnectingToEvmPeer.value && evmPeers.value.length === 0 && evmLastSynced.value > 0;
});

</script>
<template>
  <div class="items-center gap-2 space-y-2">
    <div>
      <UDivider :label="$t('sidebar.sync.btc')" class="mb-2" />
      <ol class="u-text-gray-500 space-y-3 text-xs">
        <li class="text-center">
          <UButton
            v-show="canConnectToBitcoinPeer"
            variant="link"
            :padded="false"
            color="primary"
            size="xs"
            class="uppercase"
            @click="() => peersStore.connectToBitcoinPeer()"
          >
            {{ $t('sidebar.services.reconnect') }}
          </UButton>
        </li>
        <li>
          <div class=" flex items-center justify-between">
            <span class="text-xs">
              {{ $t('sidebar.services.lightning') }}
            </span>

            <UBadge v-bind="{...resolvePeerStatusBadge(isNodeConnectingToBtcPeer, btcPeers) }" variant="subtle" size="xs" />
          </div>
        </li>
        <li>
          <div class="flex items-center justify-between">
            <span class="text-xs">
              {{ $t('sidebar.services.last-block') }}
            </span>
            <div class="flex items-center">
              {{ btcLastSynced > 0
                ? precisRelativeTime(btcLastSynced)
                : '-'
              }}
              <UTooltip
                v-if="btcLastSynced > 0"
                :text="formatWithTimezone(
                  new Date(btcLastSynced),
                  'numeric-date-hour-minute-seconds'
                )"
              >
                <UIcon
                  name="i-heroicons-information-circle"
                  class="u-text-gray-600 ml-1 size-4"
                />
              </UTooltip>
            </div>
          </div>
        </li>
      </ol>

      <div class="my-4" />

      <UDivider :label="$t('sidebar.sync.evm')" class="mb-2" />

      <ol class="u-text-gray-500 space-y-3 text-xs">
        <li class="text-center">
          <UButton
            v-show="canConnectToEvmPeer"
            variant="link"
            :padded="false"
            color="primary"
            size="xs"
            class="uppercase"
            @click="() => peersStore.connectToEvmPeer()"
          >
            {{ $t('sidebar.services.reconnect') }}
          </UButton>
        </li>
        <li>
          <div class=" flex items-center justify-between">
            <span class="text-xs">
              {{ $t('sidebar.services.lithium') }}
            </span>

            <UBadge v-bind="{...resolvePeerStatusBadge(isNodeConnectingToEvmPeer, evmPeers) }" variant="subtle" size="xs" />
          </div>
        </li>

        <li>
          <div class="flex items-center justify-between">
            <span class="text-xs">
              {{ $t('sidebar.services.last-block') }}
            </span>
            <div class="flex items-center gap-1">
              {{ evmLastSynced > 0
                ? precisRelativeTime(evmLastSynced)
                : '-'
              }}
              <UTooltip
                v-if="evmLastSynced > 0"
                :text="formatWithTimezone(
                  new Date(evmLastSynced),
                  'numeric-date-hour-minute-seconds'
                )"
              >
                <UIcon
                  name="i-heroicons-information-circle"
                  class="u-text-gray-600 ml-1 size-4"
                />
              </UTooltip>
            </div>
          </div>
        </li>
      </ol>
    </div>
  </div>
</template>
