import { DATE_FORMATS } from '~/constants';
import { useSettingsStore } from '~/stores/shared/settings';

export function useLocalFormat () {
  const { d } = useI18n();

  const {
    preferences,
    DEFAULT_TIMEZONE_UTC
  } = useSettingsStore();

  const formatWithTimezone = (
    dateValue: Date,
    formatKey: keyof typeof DATE_FORMATS
  ) => {
    const formatOptions = DATE_FORMATS[formatKey];
    const customOptions = {
      ...formatOptions,
      hour12: preferences.is12Hour,
      timeZone: preferences.timezone?.code || DEFAULT_TIMEZONE_UTC.code
    };
    const formattedValue = d(dateValue, customOptions);

    return formattedValue;
  };

  return {
    formatWithTimezone
  };
}
