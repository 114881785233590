import { BitcoinPublicKey, EvmAddress } from 'hydra-node';

export const resolvePeerStatusBadge = (
  isConnecting: boolean, peer: (EvmAddress | BitcoinPublicKey)[]
): { label: string; color: string } => {
  const { t } = useI18n();

  if (isConnecting) {
    return {
      label: t('sidebar.services.badge.connecting'),
      color: 'yellow'
    };
  }
  if (peer.length > 0) {
    return {
      label: t('sidebar.services.badge.connected'),
      color: 'green'
    };
  }
  return {
    label: t('sidebar.services.badge.disconnected'),
    color: 'red'
  };
};
