<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useHydraNodeStore } from '~/stores/hydra-node';

const hydraNodeStore = useHydraNodeStore();
const { t } = useI18n();
const {
  hasNodeLostConnection,
  hasNodeInitializationError,
  isNodeInitializing
} = storeToRefs(hydraNodeStore);

/**
 * Component used to handle/react to watch effect and create error for NuxtErrorBoundary
 * to pick it up and display error page.
 */
watchEffect(() => {
  if (hasNodeInitializationError.value && !isNodeInitializing.value) {
    throw createError({
      statusCode: 503,
      statusMessage: t('errors.node.initialisation-problem')
    });
  } else if (hasNodeLostConnection.value && !isNodeInitializing.value) {
    throw createError({
      statusCode: 504,
      statusMessage: t('errors.node.connection-lost')
    });
  }
});
</script>
<template>
  <div />
</template>
