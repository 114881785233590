<script lang="ts" setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useWindowSize } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import Logo from '~/components/pro/atoms/Logo.vue';
import { Mnemonic } from 'hydra-node';
import PasteFromClipboard from '~/components/pro/molecules/Form/PasteFromClipboard.vue';
import Badge from '~/components/pro/atoms/Badge.vue';
import CopyToClipboard from '~/components/pro/atoms/CopyToClipboard.vue';
import { useHydraNodeStore } from '~/stores/hydra-node';
import { useSettingsStore } from '~/stores/shared/settings';
import { useMnemonicManager } from '~/composables/shared/useMnemonicManager';
import { Breakpoints } from '~/config';
import { MnemonicStep } from '~/enums';

const hydraNodeStore = useHydraNodeStore();
const toast = useToast();
const { t } = useI18n();
const {
  resetLocalStorageMnemonic,
  setLocalStorageMnemonic,
  generateMnemonic,
  readLocalStorageMnemonic
} = useMnemonicManager();
const { width } = useWindowSize();

const { mnemonic } = storeToRefs(hydraNodeStore);
const { preferences } = useSettingsStore();
const isModalOpen = ref(true);
const mnemonicValue = ref('');
const currentStep = ref<MnemonicStep>(MnemonicStep.START);
const is24WordsMnemonic = ref(false);

const stepMessage = computed(() => {
  switch (currentStep.value) {
    case MnemonicStep.START:
      return t('mnemonic.modal.description.start');
    case MnemonicStep.CREATE:
      return t('mnemonic.modal.description.create');
    case MnemonicStep.IMPORT:
      return t('mnemonic.modal.description.import');
    default:
      return t('mnemonic.modal.description.start');
  }
});

watch(is24WordsMnemonic, () => {
  handleCreateClick();
});

// check if localstorage is set -> if yes go direct to confirm
const checkSavedMnemonic = () => {
  if (readLocalStorageMnemonic().length) {
    mnemonicValue.value = readLocalStorageMnemonic();
    preferences.persistMnemonic = true;
    handleConfirm();
  }
};

const handleCreateClick = () => {
  currentStep.value = MnemonicStep.CREATE;
  mnemonicValue.value = generateMnemonic(is24WordsMnemonic.value);
};

// check value of mnemonic and set store value of mnemonic for init to start
const handleConfirm = () => {
  let errorMessage = '';
  try {
    const newMnemonic = Mnemonic.fromString(mnemonicValue.value);
    mnemonic.value = newMnemonic;
    if (preferences.persistMnemonic) {
      setLocalStorageMnemonic(mnemonic.value?.toString() || '');
    } else {
      resetLocalStorageMnemonic();
    }
  } catch (e) {
    resetLocalStorageMnemonic();
    errorMessage = t('mnemonic.modal.error');
  }
  toast.add({
    id: 'added-mnemonic-to-modal',
    title:
      errorMessage ||
      (readLocalStorageMnemonic().length
        ? t('mnemonic.modal.confirm-success-localstorage')
        : t('mnemonic.modal.confirm-success-message')),
    color: errorMessage ? 'red' : 'green',
    icon: errorMessage
      ? 'i-heroicons-exclamation-triangle'
      : 'i-heroicons-information-circle',
    closeButton: {
      icon: ''
    }
  });
};

onMounted(() => checkSavedMnemonic());

// auto re-open since menmonic process not finished
watch(isModalOpen, (newVal) => {
  if (!newVal) {
    isModalOpen.value = true;
  }
});
</script>
<template>
  <UModal v-model="isModalOpen">
    <UCard>
      <div class="flex flex-col gap-4">
        <div class="flex flex-col items-center justify-center">
          <div class="flex h-8 w-full items-center justify-center py-7">
            <Logo
              class="text-primary-500 size-14 lg:h-auto"
              :text="false"
            />
          </div>
          <h3
            class="text-primary my-6 items-center text-2xl font-bold tracking-tight"
          >
            {{ $t('mnemonic.modal.title') }}
          </h3>
          <p class="u-text-gray-500 py-3 text-sm font-medium">
            {{ stepMessage }}
          </p>
        </div>
        <div
          v-show="currentStep === MnemonicStep.START"
          class="flex flex-col items-center justify-center gap-6 pb-2"
        >
          <div class="flex gap-4">
            <UButton
              color="primary"
              variant="outline"
              class="uppercase"
              @click="currentStep = MnemonicStep.IMPORT"
            >
              {{ $t('mnemonic.modal.import') }}
            </UButton>
            <UButton
              class="uppercase"
              @click="handleCreateClick"
            >
              {{ $t('mnemonic.modal.create') }}
            </UButton>
          </div>
        </div>

        <div v-show="currentStep === MnemonicStep.IMPORT">
          <UFormGroup :label="$t('mnemonic.modal.phrase')">
            <UTextarea
              v-model="mnemonicValue"
              testid="mnemonic-textbox"
              :rows="width < Breakpoints.xs ? 7 : 4"
              resize
            />
          </UFormGroup>
          <div class="mt-5 flex items-center justify-center">
            <PasteFromClipboard v-model="mnemonicValue" />
          </div>
          <div class="mt-5">
            <UCheckbox v-model="preferences.persistMnemonic">
              <template #label>
                {{ $t('mnemonic.modal.save-phrase') }}
                <UTooltip :text="$t('mnemonic.modal.save-phrase-tooltip')">
                  <UIcon
                    name="i-heroicons-information-circle"
                    class="u-text-gray-600 ml-2 size-4"
                  />
                </UTooltip>
              </template>
            </UCheckbox>
          </div>
        </div>

        <div v-show="currentStep === MnemonicStep.CREATE">
          <div class="mt-5">
            <p
              class="u-text-gray-600 flex items-center justify-center gap-2 text-sm font-medium"
            >
              {{ $t('mnemonic.modal.create-12') }}
              <UToggle
                v-model="is24WordsMnemonic"
                color="primary"
                size="md"
              />
              {{ $t('mnemonic.modal.create-24') }}
            </p>
            <div
              v-show="!!mnemonicValue"
              class="mx-auto mt-4 grid w-5/6 grid-cols-3"
            >
              <Badge
                v-for="(word, index) in mnemonicValue.split(' ')"
                :key="word"
                class="m-1"
              >
                {{ `${index + 1}.` }} {{ word }}
              </Badge>
            </div>
          </div>
          <div
            v-show="!!mnemonicValue"
            class="mt-5 flex flex-col items-center justify-center gap-4"
          >
            <CopyToClipboard :text-to-copy="mnemonicValue">
              {{ $t('mnemonic.modal.copy-action') }}
            </CopyToClipboard>
            <UButton
              icon="i-heroicons-arrow-path"
              color="white"
              @click="handleCreateClick"
            >
              {{ $t('mnemonic.modal.regenerate-button') }}
            </UButton>
          </div>
          <div class="mt-5">
            <UCheckbox
              v-model="preferences.persistMnemonic"
            >
              <template #label>
                {{ $t('mnemonic.modal.save-phrase') }}
                <UTooltip :text="$t('mnemonic.modal.save-phrase-tooltip')">
                  <UIcon
                    name="i-heroicons-information-circle"
                    class="u-text-gray-600 ml-2 size-4"
                  />
                </UTooltip>
              </template>
            </UCheckbox>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="flex flex-col items-center justify-center gap-4">
          <div
            v-show="currentStep !== MnemonicStep.START"
            class="flex gap-2"
          >
            <UButton variant="outline" @click="currentStep = MnemonicStep.START">
              <template #leading>
                <UIcon
                  name="i-heroicons-arrow-long-left-20-solid"
                />
              </template>
              {{ $t('mnemonic.modal.back') }}
            </UButton>
            <UButton @click="handleConfirm">
              <template #trailing>
                <UIcon
                  name="i-heroicons-arrow-long-right-20-solid"
                />
              </template>
              {{
                currentStep === MnemonicStep.CREATE
                  ? $t('mnemonic.modal.create')
                  : $t('mnemonic.modal.import')
              }}
            </UButton>
          </div>
          <span class="u-gray-500 text-xs italic">{{
            $t('mnemonic.modal.description.your-keys')
          }}</span>
        </div>
      </template>
    </UCard>
  </UModal>
</template>
