<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { usePeerStore } from '~/stores/peers';

const peersStore = usePeerStore();
const isModalOpen = ref(false);

const { btcLastSynced, evmLastSynced } = storeToRefs(peersStore);

// auto re-open since the node is still syncing
watch(isModalOpen, (newVal) => {
  if (!newVal) {
    isModalOpen.value = true;
  }
});

onMounted(() => {
  isModalOpen.value = true;
});

</script>
<template>
  <UModal
    v-model="isModalOpen"
    :overlay="false"
  >
    <UCard>
      <div class="my-6 flex flex-col items-center justify-center gap-4">
        <UIcon
          name="i-heroicons-cog"
          class="text-primary-600 size-10 animate-spin"
        />
        <h6 class="u-text-gray-900 text-xl font-bold tracking-tight sm:text-lg">
          {{ $t('node.syncing.title') }}
        </h6>
        <p class="u-text-gray-600 text-center text-base italic leading-7">
          {{ $t('node.syncing.message') }}
        </p>
        <p
          class="u-text-gray-500 flex items-center text-sm font-semibold leading-5"
        >
          {{ $t('node.syncing.btc') }} :
          <UIcon
            :name="
              btcLastSynced > 0 ? 'i-heroicons-check-circle' : 'i-heroicons-cog'
            "
            class="text-primary-600 ml-2 size-5"
            :class="{
              'animate-spin': btcLastSynced === 0,
            }"
          />
        </p>
        <p
          class="u-text-gray-500 flex items-center text-sm font-semibold leading-5"
        >
          {{ $t('node.syncing.evm') }} :
          <UIcon
            :name="
              evmLastSynced > 0 ? 'i-heroicons-check-circle' : 'i-heroicons-cog'
            "
            class="text-primary-600 ml-2 size-5"
            :class="{
              'animate-spin': evmLastSynced === 0,
            }"
          />
        </p>
      </div>
    </UCard>
  </UModal>
</template>
