<script lang="ts" setup>

const isMobileSidebarOpen = defineModel();
</script>
<template>
  <USlideover v-model="isMobileSidebarOpen">
    <UCard class="flex flex-1 flex-col" :ui="{ header: {padding:'p-4'},body: { base: 'flex-1' }, ring: '', divide: 'divide-y divide-gray-100 dark:divide-gray-800' }">
      <template #header>
        <div class="flex items-center justify-between">
          <slot name="logo" />
          <UButton
            color="gray"
            variant="ghost"
            icon="i-heroicons-x-mark-20-solid"
            size="lg"
            class="-my-1"
            @click="isMobileSidebarOpen = false"
          />
        </div>
      </template>

      <div class="flex grow flex-col gap-y-5 overflow-y-auto">
        <div class="flex h-full flex-col justify-between">
          <div class="grow">
            <slot name="top-navigation" />
          </div>
        </div>
      </div>

      <template #footer>
        <slot name="node-information" />
        <div class="shrink pt-4 dark:border-gray-700">
          <slot name="bottom-navigation" />
        </div>
      </template>
    </UCard>
  </USlideover>

  <!-- DESKTOP SIDEBAR -->
  <div class="u-border-gray-100 hidden shrink-0 border-r lg:flex lg:w-72 lg:flex-col xl:w-[21rem]">
    <UCard class="flex flex-1 flex-col" :ui="{ header: {padding:'p-4'},body: { base: 'flex-1' }, ring: '', divide: 'divide-y divide-gray-100 dark:divide-gray-800' }">
      <template #header>
        <div class="flex items-center justify-between">
          <slot name="logo" />
        </div>
      </template>

      <div class="flex grow flex-col gap-y-5 overflow-y-auto">
        <div class="flex h-full flex-col justify-between">
          <div class="grow">
            <slot name="top-navigation" />
          </div>
        </div>
      </div>

      <template #footer>
        <slot name="node-information" />
        <UDivider />
        <div class="shrink pt-4 dark:border-gray-700">
          <slot name="bottom-navigation" />
        </div>
      </template>
    </UCard>
  </div>
</template>
